import { Card, Container, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';

import background from '../../assets/images/ibda-bg2.png';
import logo from '../../assets/images/logo/logo-green-blue.svg';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: '8vh',
    padding: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#ffffff',
    opacity: 0.95,
  },
  background: {
    position: 'absolute',
    zIndex: -10,
    bottom: 0,
    maxHeight: '100vh',
    maxWidth: '80vw',
    left: '10%',
  },
  logo: {
    marginBottom: 20,
    marginBottom: 20,
  },
}));

export default function AuthWrapper({ children, cardStyle }) {
  const classes = useStyles();

  useEffect(() => {
    const img = document.createElement('img');
    img.src = background;
    img.id = 'background-image';
    img.className = classes.background;
    document.getElementById('root').appendChild(img);
    return () => {
      document.getElementById('root').removeChild(img);
    };
  }, []);

  return (
    <Container component="main" maxWidth="sm" style={{ background: 'transparent' }}>
      <Card className={classes.paper} style={cardStyle}>
        <img src={logo} className={classes.logo} height="70px" />
        {children}
      </Card>
    </Container>
  );
}
