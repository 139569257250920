import apiManager from '../../utils/apiManager';

export class KYCService {
  static redirectToELM() {
    return apiManager.get('/api/system-users/redirect/');
  }

  static signUpWithNationalID(nationalId) {
    return apiManager.post('/api/system-users/nafath-random/', {
      national_id: nationalId,
    });
  }

  static poolSignUpCallback(trnxId) {
    return apiManager.get(`/api/system-users/pool-kyc-callback/?trans_id=${trnxId}`);
  }
}
