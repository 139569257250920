import { useState, useRef, useCallback } from 'react';

export const useTimedInterval = ({
  callback,
  delay,
  triggerCount,
  onLastTrigger,
}) => {
  const [isActive, setIsActive] = useState(false);
  const intervalRef = useRef(null);
  const countRef = useRef(0);

  // Start the interval
  const startInterval = useCallback(
    (data) => {
      if (isActive || !callback || typeof delay !== 'number') return;

      setIsActive(true);
      countRef.current = 0;

      intervalRef.current = setInterval(() => {
        if (countRef.current >= triggerCount) {
          clearInterval(intervalRef.current);
          setIsActive(false);
          onLastTrigger();
          return;
        }
        callback(data);
        countRef.current += 1;
      }, delay);
    },
    [callback, delay, triggerCount, isActive]
  );

  // Cancel the interval
  const cancelInterval = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      setIsActive(false);
    }
  }, []);

  return { startInterval, cancelInterval, isActive };
};
