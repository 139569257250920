import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  loadingButtonWrapper: {
    // margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: '#000',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function ButtonWithLoading({
  loading,
  style = {},
  buttonStyle = {},
  className = '',
  buttonClassName = '',
  ...rest
}) {
  const classes = useStyles();
  return (
    <div className={`${classes.loadingButtonWrapper} ${className}`} style={style}>
      <Button
        style={buttonStyle}
        className={buttonClassName}
        {...rest}
        disabled={loading || rest.disabled}
      />
      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
}

ButtonWithLoading.propTypes = {
  loading: PropTypes.bool,
};
