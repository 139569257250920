import { Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { KYCService } from '../../services/kyc';
import AuthWrapper from '../../components/AuthWrapper';
import { useHistory } from 'react-router-dom';
import ButtonWithLoading from '../../components/ButtonWithLoading';
import { useTimedInterval } from '../../hooks/useTimedInterval';
import { useSnackbar } from 'notistack';

export default function SignUp() {
  const [nationalId, setNationalId] = useState('');
  const signUpMutation = useMutation(KYCService.signUpWithNationalID);
  const { enqueueSnackbar } = useSnackbar();
  const [randomNumber, setRandomNumber] = useState(null);
  const history = useHistory();
  const { startInterval, cancelInterval } = useTimedInterval({
    callback: (data) =>
      KYCService.poolSignUpCallback(data).then((res) => {
        if (!res.data.token) return;
        cancelInterval();
        history.push(`/register/?id_token=${res.data.token}`);
      }),
    delay: 1000,
    triggerCount: 60,
    onLastTrigger: () => {
      enqueueSnackbar('Session expired, Please try again.', { variant: 'error' });
      setRandomNumber(null);
      signUpMutation.reset();
    },
  });

  const onSignUpClick = () => {
    signUpMutation.mutate(nationalId, {
      onSuccess(res) {
        if (res.data.random) {
          setRandomNumber(res.data.random);
          startInterval(res.data.transId);
        } else {
          enqueueSnackbar('Something went wrong.', { variant: 'error' });
        }
      },
      onError(err) {
        enqueueSnackbar(
          err.response?.data?.error ||
            err.response?.data?.national_id?.[0] ||
            'Something went wrong.',
          { variant: 'error' }
        );
      },
    });
  };

  const onClose = () => {
    history.push({
      pathname: '/sign-in',
    });
  };

  return (
    <AuthWrapper cardStyle={{ flexDirection: 'column', alignItems: 'stretch' }}>
      <Typography component="h1" variant="h5" style={{ alignSelf: 'center' }}>
        Sign-Up With National ID
      </Typography>
      <div style={{ marginTop: 40 }}>
        {randomNumber ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <Button
              color="primary"
              variant="outlined"
              disableRipple
              style={{
                height: 60,
                width: 60,
                borderRadius: '50%',
                marginBottom: 20,
                fontSize: 30,
              }}
            >
              <b>{randomNumber}</b>
            </Button>
            <Typography>Please select the above number on your device.</Typography>
          </div>
        ) : (
          <TextField
            value={nationalId}
            onChange={(e) => setNationalId(e.target.value)}
            label="National ID"
            title="National ID"
            placeholder="Please enter your National ID"
            size="sm"
            fullWidth
            style={{ marginBottom: 20 }}
          />
        )}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        {!randomNumber ? (
          <ButtonWithLoading
            variant="contained"
            color="primary"
            onClick={onSignUpClick}
            style={{ marginBottom: 10 }}
            loading={signUpMutation.isLoading}
            fullWidth
          >
            Continue
          </ButtonWithLoading>
        ) : (
          <CircularProgress
            size={24}
            style={{ alignSelf: 'center', marginBottom: 10 }}
          />
        )}
        <Button onClick={onClose}>Go Back</Button>
      </div>
    </AuthWrapper>
  );
}
